import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import './app/firebase';
import * as serviceWorker from './serviceWorker';
import Gleap from 'gleap';
import './index.scss';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
if (!process.env.REACT_APP_BASE_URL?.includes('localhost')) {
  Gleap.initialize('ITwab1ZwY2WldMFHu8P5E9sW2KyfoQP7');
}

ReactDOM.render(
  <React.StrictMode>
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito&display=swap' }],
      }}
    >
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    alert('New version available!  Ready to update?');
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  },
});
