import React from 'react';
import { useLocation } from 'react-router-dom';

import analytics from '../app/analytics';
import useAuth from '../features/user/useAuth';

export default function useGoogleAnalytics() {
  const location = useLocation();
  const { user } = useAuth();

  React.useEffect(() => {
      analytics.init({ user_id: user?.id });
  }, [user?.id]);

  React.useEffect(() => {
      const currentPath = location.pathname + location.search;
      analytics.sendPageview(currentPath);
  }, [location]);
}
