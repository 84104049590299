import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-7Y251TCTDN';

export function init(options?: any) {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  ReactGA.initialize(TRACKING_ID, { gtagOptions: { debug: isDev, ...options }, gaOptions: { debug: isDev, ...options } });
}

export function sendEvent(payload: any) {
  ReactGA.event(payload);
}

export function sendPageview(path: string) {
  ReactGA.set({ page: path });
  ReactGA.send('pageview');
}

export function set(payload: { [key: string]: any }) {
  ReactGA.set(payload);
}

const tracking = {
  init,
  sendEvent,
  sendPageview,
  set
};

export default tracking;
