import React from 'react';
import { QueryClientProvider } from 'react-query';
import AuthListener from './common/components/AuthListener';
import queryClient from './app/queryClient';
import { ToastContainer } from 'react-toastify';
import Routes from './Routes';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import ConnectionListener from './common/components/ConnectionListener.tsx';
import AppUrlListener from './common/components/AppUrlListener';

function App() {
  var iOS = !!navigator.platform && /iPhone/.test(navigator.platform);
  if (iOS) {
    const meta = document?.querySelector<HTMLElement>('link[rel="manifest"]');

    if (meta) {
      meta.setAttribute('rel', 'no-on-ios');
    }
  }
  return (
    <div className="App">
      <AppUrlListener />
      <ConnectionListener>
        <QueryClientProvider client={queryClient}>
          <AuthListener>
            <React.Suspense fallback={<></>}>
              <Routes />
            </React.Suspense>
          </AuthListener>
        </QueryClientProvider>
      </ConnectionListener>
      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
