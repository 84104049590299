import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './common/PrivateRoute';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import useGoogleAnalytics from './common/useGoogleAnalytics';

const Home = React.lazy(() => import(/* webpackChunkName: "Home" */ './pages/Home'));
const Login = React.lazy(() => import(/* webpackChunkName: "Login" */ './pages/Login'));
const Register = React.lazy(() => import(/* webpackChunkName: "Login" */ './pages/Login/Register'));
const Forgot = React.lazy(() => import(/* webpackChunkName: "Login" */ './pages/Login/Forgot'));
const Account = React.lazy(() => import(/* webpackChunkName: "Account" */ './pages/Account'));
const Setup = React.lazy(() => import(/* webpackChunkName: "Setup" */ './pages/Setup'));
const Gallery = React.lazy(() => import(/* webpackChunkName: "Gallery" */ './pages/Gallery'));
const Audience = React.lazy(() => import(/* webpackChunkName: "Audience" */ './pages/Audience'));
const Deactivated = React.lazy(() => import(/* webpackChunkName: "Deactivated" */ './pages/Deactivated'));
const EmailGather = React.lazy(() => import(/* webpackChunkName: "EmailGather" */ './pages/EmailGather'));
const Pricing = React.lazy(() => import(/* webpackChunkName: "Pricing" */ './pages/Pricing'));
const InstagramBasicAuth = React.lazy(
  () => import(/* webpackChunkName: "Instagram Basic Auth" */ './pages/Setup/ConnectSocialAccount/InstagramBasicAuth'),
);
const InstagramBasicReAuth = React.lazy(
  () => import(/* webpackChunkName: "Instagram Basic Auth" */ './pages/Account/components/InstagramBasicReAuth'),
);

function Routes() {
  useGoogleAnalytics();

  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/register">
        <Register />
      </Route>
      <Route path="/forgot">
        <Forgot />
      </Route>
      <Route path="/deactivated">
        <Deactivated />
      </Route>
      <Route path="/connect/:sourceId/:sourceName">
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY} useEnterprise>
          <EmailGather />
        </GoogleReCaptchaProvider>
      </Route>
      <PrivateRoute path="/account/:tab?">
        <Account />
      </PrivateRoute>
      <PrivateRoute path="/setup/instagram-basic-auth/:additional?">
        <InstagramBasicAuth />
      </PrivateRoute>
      <PrivateRoute path="/setup/instagram-basic-reauth">
        <InstagramBasicReAuth />
      </PrivateRoute>
      <PrivateRoute path="/setup">
        <Setup />
      </PrivateRoute>
      <PrivateRoute path="/gallery">
        <Gallery />
      </PrivateRoute>
      <PrivateRoute path="/audience">
        <Audience />
      </PrivateRoute>
      <PrivateRoute path="/pricing">
        <Pricing />
      </PrivateRoute>
      <PrivateRoute path="/">
        <Home />
      </PrivateRoute>
    </Switch>
  );
}

export default Routes;
