import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useAuth from '../../../features/user/useAuth';
import PageLoader from '../PageLoader';

interface MessageProps {
  children: React.ReactElement;
}

interface LocationState {
  from: {
    pathname: string;
  };
}

const AuthListener: React.FC<MessageProps> = ({ children }) => {
  const { user, logout, setIsLoading, isLoading } = useAuth();
  const history = useHistory();
  const location = useLocation<LocationState>();
  useEffect(() => {
    if (user) {
      setIsLoading(false);
      const { emailVerified, displayName, id } = user;
      if (!emailVerified) {
        logout();
        return;
      }
      if (location.pathname.includes('setup') || location.pathname.includes('payment-info')) {
        return;
      }
      if (displayName && id && location.pathname.includes('login')) {
        const { from } = location.state || { from: { pathname: '/' } };
        history.replace(from);
      }
    }
    // eslint-disable-next-line
  }, [history, logout, user, location.state, location.pathname]);

  if (isLoading) {
    return <PageLoader loading />;
  }

  return children;
};

export default AuthListener;
