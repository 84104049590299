import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCeOTbRSwpeLpa0gN3OPTtQppiMecVua9s',
  authDomain: 'omnic-prod.firebaseapp.com',
  projectId: 'omnic-prod',
  storageBucket: 'omnic-prod.appspot.com',
  messagingSenderId: '818090979576',
  appId: '1:818090979576:web:52562c260f7fce74cecb0d',
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
