import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from '../../app/firebase';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../app/store';
import { loginSuccess, logoutSuccess } from './userSlice';
import { messageAction, messageClear } from '../message/messageSlice';
import {
  MESSAGE_CHANGE_PASSWORD_FAILURE,
  MESSAGE_CHANGE_PASSWORD_SUCCESS,
  MESSAGE_EMAIL_UNVERIFIED,
  MESSAGE_ERROR,
  MESSAGE_FORGOT_FAILURE,
  MESSAGE_FORGOT_SUCCESS,
  MESSAGE_INVALID_LOGIN,
  MESSAGE_REGISTER_FAILURE,
  MESSAGE_REGISTER_SUCCESS,
  MESSAGE_SUCCESS,
} from '../../constants';
import axios from '../../app/axios';
import queryClient from '../../app/queryClient';
import Gleap from 'gleap';
import GA from '../../app/analytics';

interface LocationState {
  from: {
    pathname: string;
  };
}

function useAuth() {
  const [isLoading, setIsLoading] = useState(true);
  const [authToken, setAuthToken] = useState('');
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const location = useLocation<LocationState>();
  const auth = firebase.auth();

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged((user) => {
      if (user) {
        user?.getIdToken().then((token) => {
          setAuthToken(token);
          if (!user) {
            setIsLoading(false);
            return;
          }
          const { uid: id, displayName, emailVerified, email } = user;
          Gleap.identify(id, {
            name: displayName || undefined,
            email: email || undefined,
          });
          dispatch(loginSuccess({ id, displayName, emailVerified }));
        });
      } else {
        setIsLoading(false);
        dispatch(logoutSuccess());
      }
    });

    return () => unsubscribe();
  }, [auth, dispatch]);

  const login = async (userObj: any) => {
    const { email, password } = userObj;
    const { from } = location.state || { from: { pathname: '/' } };
    const res = await auth.signInWithEmailAndPassword(email, password).catch((error) => {
      dispatch(messageAction({ type: MESSAGE_ERROR, content: MESSAGE_INVALID_LOGIN }));
    });
    if (!res) {
      return;
    }
    const { user } = res;
    if (!user?.emailVerified) {
      await auth.signOut();
      dispatch(messageAction({ type: MESSAGE_ERROR, content: MESSAGE_EMAIL_UNVERIFIED }));
      return;
    }
    const response = { id: user.uid, displayName: user.displayName, emailVerified: user.emailVerified };
    GA.set({ user_id: user.uid });
    GA.sendEvent({ category: 'User', action: 'login' });
    dispatch(loginSuccess(response));
    dispatch(messageClear());
    history.replace(from);
  };

  const signup = async (userObj: any) => {
    const { email, password, displayName, code } = userObj;
    dispatch(messageClear());
    try {
      const { user } = await auth.createUserWithEmailAndPassword(email, password);
      if (user) {
        await user.updateProfile({ displayName });
        await user.sendEmailVerification();
        const { uid: userId } = user;
        await axios.post('/users', { userId, email, displayName, code });
        dispatch(messageAction({ type: MESSAGE_SUCCESS, content: MESSAGE_REGISTER_SUCCESS }));
        GA.sendEvent({ category: 'User', action: 'register' });
      }
    } catch (e) {
      dispatch(messageAction({ type: MESSAGE_ERROR, content: MESSAGE_REGISTER_FAILURE }));
      GA.sendEvent({ category: 'User', action: 'register_fail' });
    }
  };

  const changePassword = async (userObj: any) => {
    const { currentPassword, newPassword } = userObj;
    const user = auth.currentUser;
    try {
      if (user?.email != null) {
        const credential = firebase.auth.EmailAuthProvider.credential(user?.email, currentPassword);
        await user?.reauthenticateWithCredential(credential);
        await user?.updatePassword(newPassword);
      }
      dispatch(messageAction({ type: MESSAGE_SUCCESS, content: MESSAGE_CHANGE_PASSWORD_SUCCESS }));
    } catch (e) {
      dispatch(messageAction({ type: MESSAGE_ERROR, content: MESSAGE_CHANGE_PASSWORD_FAILURE }));
    }
  };

  const forgot = async (userObj: any) => {
    const { email } = userObj;
    await auth
      .sendPasswordResetEmail(email)
      .then((val) => {
        dispatch(messageAction({ type: MESSAGE_SUCCESS, content: MESSAGE_FORGOT_SUCCESS }));
      })
      .catch((error) => {
        dispatch(messageAction({ type: MESSAGE_ERROR, content: MESSAGE_FORGOT_FAILURE }));
      });
  };

  const logout = useCallback(async () => {
    await auth.signOut();
    GA.set({ user_id: null });
    Gleap.clearIdentity();
    dispatch(logoutSuccess());
    queryClient.clear();
  }, [auth, dispatch]);

  return {
    user,
    isLoading,
    setIsLoading,
    authToken,
    login,
    signup,
    changePassword,
    logout,
    forgot,
  };
}

export default useAuth;
