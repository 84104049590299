import axios from 'axios';
import firebase from './firebase';

const defaultOptions = {
  baseURL: process.env.REACT_APP_BASE_API,
};

const instance = axios.create(defaultOptions);

let userInstance: any;

instance.interceptors.request.use(async (req) => {
  const token = await userInstance.getIdToken();
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  req.headers['Authorization'] = `Bearer ${token}`;
  return req;
});

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    userInstance = user;
    const token = await user.getIdToken();
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    instance.defaults.headers.common['Authorization'] = '';
  }
});

export default instance;
