import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ConnectionListenerProps {
  children: React.ReactElement;
}

interface LocationState {
  from: {
    pathname: string;
  };
}

const ConnectionListener: React.FC<ConnectionListenerProps> = ({ children }) => {
  const location = useLocation<LocationState>();
  useEffect(() => {
    if (!navigator.onLine) {
      alert(
        'Uh oh! Looks like you are offline, Stashy requires an internet connection, please check your connection and try again!',
      );
    }
  }, [location]);

  return children;
};

export default ConnectionListener;
